import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import category from "./data/single.json";
import { CategoryArticleServices } from "src/services/CategoryArticleServices";
import toast from "react-hot-toast";

export function AdminCategoryEducationEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const categoryArticleServices = new CategoryArticleServices();

  const [form, setForm] = useState({
    name: "",
    image: "",
    preview: "",
  });

  useEffect(() => {
    fetchCategory();
  }, []);

  async function fetchCategory() {
    const res = await categoryArticleServices.GetSingle({ id });
    if (res) {
      setForm({
        name: res.data.name,
        image: null,
        preview: res.data.image,
      });
    }
  }

  function handleChange(e) {
    if (e.target.name == "image") {
      setForm({
        ...form,
        [e.target.name]: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      });
      return;
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const res = await categoryArticleServices.Update({
      id,
      name: form.name,
      image: form.image,
    });

    if (res) {
      toast.success("Berhasil mengubah kategori edukasi");
      navigate("/category-education");
    }
  }

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Kategori Edukasi</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                color="dark"
                label={"Judul"}
                name={"name"}
                value={form.name}
                handleChange={handleChange}
                type={"text"}
                required={true}
                placeholder={"Judul Kategori Edukasi"}
              />
            </div>
            <div className="mt-2">
              <InputImage
                color="dark"
                label={"Gambar"}
                name={"image"}
                handleChange={handleChange}
                type={"image/png, image/jpeg"}
                preview={form.preview}
                required={false}
                placeholder={"Gambar Edukasi"}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <ButtonComponent
                color="bg-slate-400"
                action={() => {
                  navigate("/category-education");
                }}
                title={"Cancel"}
                type="button"
              />
              <ButtonComponent type="submit" title={"Submit"} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
