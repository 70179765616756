import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { InputSelect } from "src/components/input/input-select";
import { InputTextarea } from "src/components/input/input-textarea";
import { NavbarComponent } from "src/components/navbar";
import { CategoryPromotionServices } from "src/services/CategoryPromotionServices";
import { PromotionServices } from "src/services/PromotionServices";

export function CreatePromotionPage() {
  const navigate = useNavigate();

  const categoryPromotionServices = new CategoryPromotionServices();
  const promotionServices = new PromotionServices();

  const [form, setForm] = useState({
    name: "",
    image: "",
    preview: "",
    price: "",
    description: "",
    category_id: "",
  });
  const [categories, setCategories] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  async function fetchCategories() {
    const res = await categoryPromotionServices.GetAll({ page: 1, limit: 100 });

    if (res) {
      setForm({ ...form, category_id: res.data.data[0].id });

      const tempCategories = res.data.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setCategories({
        data: tempCategories,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  function handleChange(e) {
    if (e.target.name === "image") {
      setForm({
        ...form,
        [e.target.name]: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      });
      return;
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const res = await promotionServices.Create({
      name: form.name,
      image: form.image,
      price: form.price,
      description: form.description,
      category_id: form.category_id,
    });

    if (res) {
      toast.success("Berhasil menambahkan produk");
      navigate("/promotion");
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Tambah Produk"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <form onSubmit={handleSubmit} className="mt-4 w-11/12">
        <div className="mt-0">
          <InputDefault
            color="dark"
            label={"Nama Produk"}
            name={"name"}
            value={form.name}
            handleChange={handleChange}
            type={"text"}
            required={true}
            placeholder={"Masukkan nama produk anda"}
          />
        </div>
        <div className="mt-2">
          <InputDefault
            color="dark"
            label={"Harga"}
            name={"price"}
            value={form.price}
            handleChange={handleChange}
            type={"number"}
            required={true}
            placeholder={""}
          />
        </div>
        <div className="mt-2">
          <InputTextarea
            color="dark"
            label={"Deskripsi"}
            name={"description"}
            value={form.description}
            handleChange={handleChange}
            required={true}
            placeholder={"Deskripsi Produk"}
          />
        </div>
        <div className="mt-2">
          <InputSelect
            color="dark"
            label={"Kategori"}
            name={"category_id"}
            value={form.category_id}
            handleChange={handleChange}
            required={true}
            placeholder={"Kategori Produk"}
            options={categories?.data}
          />
        </div>
        <div className="mt-2">
          <InputImage
            color="dark"
            label={"Gambar"}
            name={"image"}
            handleChange={handleChange}
            type={"image/png, image/jpeg"}
            preview={form.preview}
            required={true}
            placeholder={"Gambar Prouk"}
          />
        </div>

        <div className="mt-6 flex flex-col gap-2">
          <ButtonComponent
            color="bg-slate-400"
            action={() => {
              navigate("/promotion");
            }}
            title={"Cancel"}
            type="button"
          />
          <ButtonComponent type="submit" title={"Submit"} />
        </div>
      </form>

      <br />
      <br />
      <br />
    </div>
  );
}
