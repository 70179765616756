import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData, headersFormUrl } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class CategoryArticleServices {
  async GetAll({ page, limit, search = "" }) {
    try {
      const res = await axios.get(
        `${baseUrl}/category-article?page=${page}&limit=${limit}&search=${search}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetSingle({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/category-article/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ name, image }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);

    try {
      const res = await axios.post(`${baseUrl}/category-article`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Update({ name, image, id }) {
    const formData = new FormData();
    formData.append("name", name);
    if (image != null) formData.append("image", image);

    formData.append("_method", "PUT");

    try {
      const res = await axios.post(
        `${baseUrl}/category-article/${id}`,
        formData,
        { headers: headersFormData }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/category-article/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
