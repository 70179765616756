import { useEffect, useState } from "react";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonComponent, ToggleButton } from "src/components/button";
import { NavbarComponent } from "src/components/navbar";
import { CategoryPromotionServices } from "src/services/CategoryPromotionServices";
import { PromotionServices } from "src/services/PromotionServices";
import { UserServices } from "src/services/UserServices";
import { PromotionCard } from "./component/PromotionCard";
import avatar from "src/assets/icon/avatar.png";

export function ProfilePromotionPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const userServices = new UserServices();
  const categoryPromotionServices = new CategoryPromotionServices();
  const promotionServices = new PromotionServices();

  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  const [promotions, setPromotions] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  const [userProfile, setUserProfile] = useState({
    id: "",
    name: "",
    email: "",
    image: "",
    role: "",
    point: "",
  });

  useEffect(() => {
    fetchUserProfile();
    fetchCategories();
    fetchPromotions();
  }, []);

  async function fetchUserProfile() {
    const res = await userServices.GetProfileById({ id });

    if (res) {
      setUserProfile(res.data);
    }
  }

  async function fetchCategories() {
    const res = await categoryPromotionServices.GetAll({ page: 0, limit: 100 });

    if (res) {
      setCategories({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  async function fetchPromotions(pCategory = "") {
    const res = await promotionServices.GetAll({
      page: 0,
      limit: 10,
      search: "",
      category_id: pCategory,
      created_by: id,
    });

    if (res) {
      setPromotions({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  async function fetchMore(pCategory = "") {
    const res = await promotionServices.GetAll({
      page: promotions.pagination.current_page + 1,
      limit: 10,
      category_id: pCategory,
      created_by: id,
    });
    if (res) {
      setPromotions({
        data: [...promotions.data, ...res.data.data],
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Profile Toko"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="w-11/12 mt-4">
        <div className="bg-white shadow-s1 w-full rounded-lg flex p-5 gap-4">
          <div className="w-20 h-20 rounded-full relative overflow-hidden flex justify-center items-center">
            <img
              src={userProfile.image ? userProfile.image : avatar}
              className="w-full h-auto absolute"
            />
          </div>
          <div className="w-2/3 flex flex-col justify-center">
            <h4 className="font-semibold f-h4 line-clamp-2">
              {userProfile.name}
            </h4>
            <p className="font-semibold f-p2-r">{userProfile.email}</p>
            <p className="font-semibold mt-2 f-p2-r text-primary-main">
              Point {userProfile.point}
            </p>
          </div>
        </div>
      </div>

      <div className="w-11/12 mt-4 overflow-scroll">
        <div className="flex gap-1">
          <ToggleButton
            key={"all"}
            title={"Semua"}
            active={category == ""}
            handleClick={() => {
              setCategory("");
              fetchPromotions("");
            }}
          />
          {categories.data.map((item, index) => {
            return (
              <ToggleButton
                key={index}
                title={item.name}
                active={category == item.id}
                handleClick={() => {
                  setCategory(item.id);
                  fetchPromotions(item.id);
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="w-11/12 mt-4">
        <div className="grid grid-cols-2 gap-2">
          {promotions.data.length > 0 &&
            promotions.data.map((promotion, i) => (
              <PromotionCard
                key={i}
                id={i}
                slug={promotion.slug}
                image={promotion.image}
                name={promotion.name}
                category={promotion.category}
                price={promotion.price}
                rating={promotion.rating}
              />
            ))}
        </div>
      </div>

      <div className="mt-4 w-11/12">
        <ButtonComponent
          title={"Muat ulang"}
          action={() => {
            fetchMore(category);
          }}
        />
      </div>
    </div>
  );
}
