import { FiArrowLeft, FiHome, FiSmile } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { NavbarComponent } from "src/components/navbar";
import { BottomNavbarComponent } from "src/components/navbar/BottomNavbarComponent";

export function CommunityPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Komunitas"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="w-11/12 mt-4 h-screen bg-white shadow-lg rounded-xl flex flex-col justify-center">
        <FiSmile className="text-6xl text-primary-main mx-auto" />
        <h4 className="f-h4 text-center mt-2">
          Fitur komunitas akan segera tersedia
        </h4>
      </div>
      <br />
      <br />
      <br />
      <div className="w-full fixed bottom-0">
        <BottomNavbarComponent />
      </div>
    </div>
  );
}
