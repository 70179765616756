import React from "react";
import { useNavigate } from "react-router-dom";
import imageLogo from "src/assets/logo.svg";
import { ButtonComponent } from "src/components/button";
import authIllustration from "src/assets/ilustration/auth.png";

export function StartPage() {
  const navigate = useNavigate();

  return (
    <div className="relative h-full flex flex-col items-center justify-center overflow-hidden">
      <div className="absolute -top-10 right-0 left-0 w-full">
        <img src={authIllustration} className="w-full" />
      </div>

      <div>
        <img src={imageLogo} alt="Logo" />
      </div>

      <div className="bg-primary-main absolute bottom-0 w-full flex justify-center items-center h-24">
        <div className="w-1/3">
          <ButtonComponent
            title="Mulai"
            action={() => {
              navigate("/sign-in");
            }}
          />
        </div>
      </div>
    </div>
  );
}
