import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class ReviewerServices {
  async GetAll({ page, limit, promotion_id }) {
    try {
      const res = await axios.get(
        `${baseUrl}/reviewer?page=${page}&limit=${limit}&promotion_id=${promotion_id}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetSingle({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/reviewer/${id}`, { headers });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ rating, comment, transaction_id, parent_id }) {
    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("comment", comment);
    formData.append("transaction_id", transaction_id);
    if (parent_id) {
      formData.append("parent_id", parent_id);
    }

    try {
      const res = await axios.post(`${baseUrl}/review`, formData, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
