import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import all from "./data/all.json";
import { CategoryPromotionServices } from "src/services/CategoryPromotionServices";

export function AdminCategoryPromotionPage() {
  const navigate = useNavigate();

  const categoryPromotionServices = new CategoryPromotionServices();

  const [categories, setCategories] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  async function fetchCategories() {
    const res = await categoryPromotionServices.GetAll({ page: 1, limit: 10 });
    if (res) {
      setCategories({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  async function fetchMore({ index, search = "" }) {
    const res = await categoryPromotionServices.GetAll({
      page: index,
      limit: 10,
      search,
    });
    if (res) {
      setCategories({
        data: [...res.data.data],
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "name", name: "Nama" },
    { code: "status", name: "Status" },
    { code: "action", name: "Action" },
  ];

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <div className="flex gap-4 flex-col md:flex-row md:items-end">
        <div className="flex gap-2 items-end">
          <div className="">
            <InputDefault
              color="dark"
              label={"Search"}
              name={"search"}
              value={search}
              handleChange={(e) => {
                setSearch(e.target.value);
              }}
              type={"text"}
              required={true}
              placeholder={"Pencarian"}
            />
          </div>
          <div className="">
            <ButtonComponent
              title="Terapkan"
              type="button"
              action={async () => {
                await fetchMore({
                  index: 1,
                  search,
                });
              }}
            />
          </div>
        </div>

        <div className="flex lg:justify-end justify-start grow">
          <div className="">
            <ButtonComponent
              title="ADD CATEGORY"
              type="button"
              action={() => {
                navigate("/category-promotion/add");
              }}
            />
          </div>
        </div>
      </div>

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "bg-yellow-500",
            name: "Edit",
            callback: (id) => {
              const slug = categories.data.find((item) => item.id === id).slug;
              navigate("/category-promotion/edit/" + slug);
            },
          },
          {
            color: "bg-red-500",
            name: "Delete",
            callback: async (id) => {
              const slug = categories.data.find((item) => item.id === id).slug;
              const res = await categoryPromotionServices.Delete({ id: slug });
              if (res) {
                toast.success("Berhasil menghapus kategori promosi");
                fetchCategories();
              }
            },
          },
        ]}
        data={categories.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={categories.pagination.current_page}
          data={Array.from(
            { length: categories.pagination.last_page },
            (_, index) => index + 1
          )}
          callback={async (index) => {
            await fetchMore({ index: index + 1 });
          }}
        />
      </div>
    </div>
  );
}
