import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadComponent from "src/components/load";
import { AuthServices } from "src/services/AuthServices";
import { UserServices } from "src/services/UserServices";

export function AdminMyProfilePage() {
  const navigate = useNavigate();

  const userServices = new UserServices();
  const authServices = new AuthServices();

  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    const response = await userServices.GetProfile();
    if (!response) return;
    setUser(response.data);
    // console.log(response.data);
  }

  if (user == null) {
    return <LoadComponent />;
  }

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6 bg-white shadow-s1 w-full rounded-lg flex p-5 gap-4">
          <div className="w-1/3">
            <img
              src="https://www.w3schools.com/howto/img_avatar.png"
              className="w-full rounded-full"
            />
          </div>
          <div className="w-2/3 flex flex-col justify-center">
            <h4 className="font-semibold f-h4 line-clamp-2">{user.name}</h4>
            <p className="font-semibold f-p2-r">{user.email}</p>

            <button
              onClick={() => {
                navigate("/my-profile/edit");
              }}
              className="f-p2-r bg-primary-main mt-3 py-1 px-4 w-fit rounded-full text-white"
            >
              Ubah Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
