import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class NewsServices {
  async GetAll({ page, limit, search = "", category_id = "" }) {
    try {
      const res = await axios.get(
        `${baseUrl}/news?page=${page}&limit=${limit}&search=${search}&category_id=${category_id}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetSingle({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/news/${id}`, { headers });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ title, content, image, published_at, status, category_id }) {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("image", image);
    formData.append("published_at", published_at);
    formData.append("status", status);
    formData.append("category_id", category_id);

    try {
      const res = await axios.post(`${baseUrl}/news`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Update({
    title,
    content,
    image,
    published_at,
    status,
    category_id,
    id,
  }) {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (image !== null) formData.append("image", image);
    formData.append("published_at", published_at);
    formData.append("status", status);
    formData.append("category_id", category_id);
    formData.append("_method", "PUT");

    try {
      const res = await axios.post(`${baseUrl}/news/${id}`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async Delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/news/${id}`, { headers });
      if (res.status === 200) {
        return true;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
