import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class DiscussionServices {
  async GetAll({ page, limit, promotion_id = "" }) {
    try {
      const res = await axios.get(
        `${baseUrl}/comment?page=${page}&limit=${limit}&promotion_id=${promotion_id}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
  async Create({ comment, promotion_id, parent_id = "" }) {
    const formData = new FormData();
    formData.append("comment", comment);
    formData.append("promotion_id", promotion_id);
    if (parent_id !== "") {
      formData.append("parent_id", parent_id);
    }
    try {
      const res = await axios.post(`${baseUrl}/comment`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Update({ name, image, price, description, category_id, id, status }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("category_id", category_id);
    formData.append("status", status);

    try {
      const res = await axios.put(`${baseUrl}/promotion/${id}`, formData, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/promotion/${id}`, { headers });
      if (res.status === 200) {
        return true;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
