import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  FiArrowLeft,
  FiArrowRight,
  FiHome,
  FiMinus,
  FiPlus,
  FiStar,
} from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonComponent } from "src/components/button";
import LoadComponent from "src/components/load";
import { NavbarComponent } from "src/components/navbar";
import { Currency } from "src/methods/Currency";
import { PromotionServices } from "src/services/PromotionServices";
import { TransactionServices } from "src/services/TransactionServices";
import { InputTextarea } from "src/components/input/input-textarea";
import { DiscussionServices } from "src/services/DiscussionServices";
import { useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { ReviewerServices } from "src/services/ReviewerServices";
import avatar from "src/assets/icon/avatar.png";

export function DetailPromotionPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const promotionServices = new PromotionServices();
  const transactionServices = new TransactionServices();
  const discussionServices = new DiscussionServices();

  const { user } = useContext(UserContext);

  const [promotion, setPromotion] = useState(null);
  const [discussion, setDiscussion] = useState(null);
  const [comment, setComment] = useState("");

  const [count, setCount] = useState(1);

  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    if (promotion) fetchDiscussion();
  }, [promotion]);

  async function fetch() {
    const res = await promotionServices.GetSingle({ id });
    if (res) {
      setPromotion(res.data);
    }
  }
  async function fetchDiscussion() {
    const res = await discussionServices.GetAll({
      page: 1,
      limit: 10,
      promotion_id: promotion.id,
    });
    if (res) {
      setDiscussion(res.data.data);
    }
  }

  async function handleComment() {
    const res = await discussionServices.Create({
      comment: comment,
      promotion_id: promotion.id,
    });
    if (res) {
      toast.success("Berhasil komentar");
      fetchDiscussion();
    }
  }

  async function handleBuy() {
    const res = await transactionServices.Create({
      promotion_id: promotion.id,
      quantity: count,
    });

    if (res) {
      const id = res.data.invoice_number;

      window.open(
        `https://api.whatsapp.com/send?phone=6282112963092&text=Halo%20Tim%20Support%20${promotion.created_user.name},%0A%0ASaya%20ingin%20mengonfirmasi%20pembelian%20yang%20baru%20saja%20saya%20lakukan:%0A%0ANomor%20Faktur:%20${id}%0AProduk%20yang%20Dibeli:%20${promotion.name}%0AJumlah:%20${count}%0A%0ASaya%20telah%20melakukan%20pembayaran%20untuk%20pesanan%20ini%20dan%20sangat%20menantikan%20pengirimannya.%0A%0AMohon%20konfirmasi%20penerimaan%20pesanan%20ini%20dan%20berikan%20informasi%20lebih%20lanjut%20terkait%20pengiriman.%0A%0ATerima%20kasih%20atas%20bantuannya.%0A%0ASalam,%0A${user.name}`
      );

      toast.success("Berhasil beli");
    }
  }

  if (promotion == null) {
    return <LoadComponent />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Detail Produk"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div>
        <img src={promotion.image} />
      </div>

      <div className="mt-4 w-11/12">
        <h4 className="f-h4">{promotion.name}</h4>

        <div className="flex justify-between items-center">
          <p className="f-p1-sb mt-4 text-primary-main">
            {Currency(promotion.price)}
          </p>
          {/* <p className="f-p1-sb mt-4 ">Stok: 100</p> */}
        </div>
        <p className="f-p1-r mt-4">
          {promotion.description ?? "Tidak ada deskripsi"}
        </p>
      </div>

      <div className="mt-4 w-11/12">
        <div className="bg-white w-full rounded-lg flex gap-4">
          <div className="w-14">
            <img
              src={promotion.created_user.image ?? avatar}
              className="w-full rounded-full"
            />
          </div>
          <div className="grow flex flex-col justify-center">
            <p className="font-semibold f-p1-sb line-clamp-2">
              {promotion.created_user.name}
            </p>
            <p className="font-semibold f-p2-r">{promotion.published_at}</p>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => {
                navigate(`/promotion/profile/${promotion.created_user.id}`);
              }}
              className=""
            >
              <FiArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6 w-11/12">
        <h5 className="f-h5">Ulasan Pembeli</h5>

        <div className="mt-4 flex flex-col gap-4">
          {promotion.review.map((item, i) => (
            <>
              <ReviewProduct
                key={i}
                {...item}
                promotion={promotion}
                callback={fetch}
              />
              <div className="ml-10">
                {item.children && (
                  <ReviewProduct
                    key={i}
                    {...item.children}
                    rating={null}
                    child={true}
                  />
                )}
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="mt-6 w-11/12">
        <h5 className="f-h5">Diskusi</h5>

        <div className="mt-4 flex flex-col gap-4">
          <InputTextarea
            label={""}
            name={"content"}
            handleChange={(e) => {
              setComment(e.target.value);
            }}
            type={"text"}
            required={true}
            placeholder={"Tulis diskusi"}
            color={"dark"}
          />
          <button
            onClick={handleComment}
            className="bg-white border border-primary-main rounded-lg p-2 flex w-fit text-sm"
          >
            Kirim
          </button>
          {discussion == null ? (
            <LoadComponent />
          ) : (
            discussion.map((item, i) => (
              <>
                <CommentProduct key={i} {...item} callback={fetchDiscussion} />
                <div className="ml-10">
                  {item.children &&
                    item.children.map((item, i) => (
                      <>
                        <CommentProduct
                          key={i}
                          {...item}
                          callback={fetchDiscussion}
                          child={true}
                        />
                      </>
                    ))}
                </div>
              </>
            ))
          )}
        </div>
      </div>

      <br />
      <br />
      <br />
      {user && user.id != promotion.created_user.id && (
        <div className="fixed bg-white shadow-s1 w-full bottom-0 flex justify-center max-w-screen-sm">
          <div className="w-11/12 py-3 flex items-center justify-between">
            <div className="flex gap-2 items-center">
              <button
                onClick={() => {
                  if (count > 1) {
                    setCount(count - 1);
                  }
                }}
                className="bg-white border border-primary-main rounded-lg p-2"
              >
                <FiMinus />
              </button>
              <h4 className="f-h4 w-14 text-center">{count}</h4>
              <button
                onClick={() => {
                  setCount(count + 1);
                }}
                className="bg-white border border-primary-main rounded-lg p-2"
              >
                <FiPlus />
              </button>
            </div>

            <div className="w-1/2">
              <ButtonComponent title="BELI" action={handleBuy} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ReviewProduct({
  comment,
  rating,
  created_user,
  created_at_string,
  callback = () => {},
  promotion,
  id,
  transaction_id,
  child = false,
  children,
}) {
  const { user } = useContext(UserContext);

  let canReply = false;
  if (!child && !children) {
    canReply = promotion.created_by === user.id;
  } else {
    canReply = false;
  }
  const [reply, setReply] = useState(false);
  const [commentReply, setCommentReply] = useState("");

  const RenderRating = ({ rating }) => {
    if (rating == null) return null;
    return (
      <>
        {Array(rating)
          .fill(0)
          .map((_, i) => (
            <FaStar key={i} className="text-primary-main" />
          ))}
        {rating < 5 &&
          Array(5 - rating)
            .fill(0)
            .map((_, i) => <FiStar key={i} className="text-primary-main" />)}
      </>
    );
  };

  const reviewerServices = new ReviewerServices();
  async function handleReply() {
    const res = await reviewerServices.Create({
      comment: commentReply,
      parent_id: id,
      transaction_id: transaction_id,
      rating: 0,
    });
    if (res.code === 200) {
      toast.success("Berhasil balas");
      setReply(false);
      callback();
    } else {
      toast.error("Gagal balas");
    }
  }

  return (
    <div>
      <div className="bg-white w-full rounded-lg flex gap-4">
        <div className="w-10 h-10 rounded-full flex justify-center items-center relative overflow-hidden">
          <img
            src={created_user.image ?? avatar}
            className="w-full h-auto absolute "
          />
        </div>
        <div className="grow flex flex-col justify-center">
          <p className="font-semibold f-p2-sb line-clamp-2">
            {created_user.name}
          </p>
          <p className="font-semibold f-p2-r">{created_at_string}</p>
        </div>
      </div>

      <div className="flex gap-0.5 mt-2">
        <RenderRating rating={rating} />
      </div>

      <div className="mt-2">
        <p className="f-p2-r">
          {comment ?? "Tidak ada deskripsi ulasan pembeli"}
        </p>
      </div>
      {canReply && (
        <button
          className="text-primary-main text-sm"
          onClick={(e) => {
            e.preventDefault();
            setReply(!reply);
          }}
        >
          Balas
        </button>
      )}
      <div className="space-y-2">
        {reply && (
          <>
            <InputTextarea
              label={""}
              name={"content"}
              handleChange={(e) => {
                setCommentReply(e.target.value);
              }}
              type={"text"}
              required={true}
              placeholder={"Tulis Balasan"}
              color={"dark"}
            />
            <button
              onClick={handleReply}
              className="bg-white border border-primary-main rounded-lg p-2 flex w-fit text-sm"
            >
              Kirim
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function CommentProduct({
  id,
  promotion_id,
  comment,
  rating,
  created_user,
  created_at_string,
  callback = () => {},
  children,
  child,
}) {
  const [reply, setReply] = useState(false);
  const [commentReply, setCommentReply] = useState("");
  const RenderRating = ({ rating }) => {
    if (rating == null) return null;
    return (
      <>
        {Array(rating)
          .fill(0)
          .map((_, i) => (
            <FaStar key={i} className="text-primary-main" />
          ))}
        {rating < 5 &&
          Array(5 - rating)
            .fill(0)
            .map((_, i) => <FiStar key={i} className="text-primary-main" />)}
      </>
    );
  };
  const discussionServices = new DiscussionServices();
  async function handleReply() {
    const res = await discussionServices.Create({
      comment: commentReply,
      promotion_id: promotion_id,
      parent_id: id,
    });
    if (res.code === 200) {
      toast.success("Berhasil balas");
      setReply(false);
      callback();
    } else {
      toast.error("Gagal balas");
    }
  }
  return (
    <>
      <div>
        <div className="bg-white w-full rounded-lg flex gap-4">
          <div className="w-10 h-10 rounded-full relative flex justify-center items-center overflow-hidden">
            <img
              src={created_user.image ?? avatar}
              className="w-full h-auto absolute "
            />
          </div>
          <div className="grow flex flex-col justify-center">
            <p className="font-semibold f-p2-sb line-clamp-2">
              {created_user.name}
            </p>
            <p className="font-semibold f-p2-r">{created_at_string}</p>
          </div>
        </div>

        <div className="mt-2">
          <p className="f-p2-r">
            {comment ?? "Tidak ada deskripsi ulasan pembeli"}
          </p>
        </div>
        <div className=" space-y-2">
          {!child && (
            <button
              className="text-primary-main text-sm"
              onClick={(e) => {
                e.preventDefault();
                setReply(!reply);
              }}
            >
              Balas
            </button>
          )}
          {reply && (
            <>
              <InputTextarea
                label={""}
                name={"content"}
                handleChange={(e) => {
                  setCommentReply(e.target.value);
                }}
                type={"text"}
                required={true}
                placeholder={"Tulis diskusi"}
                color={"dark"}
              />
              <button
                onClick={handleReply}
                className="bg-white border border-primary-main rounded-lg p-2 flex w-fit text-sm"
              >
                Kirim
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
