import { useEffect, useState } from "react";
import { FiStar } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { RatingServices } from "src/services/RatingServices";

export function AdminDashboardPage() {
  const ratingServices = new RatingServices();

  const [ratings, setRatings] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchRating();
  }, []);

  async function fetchRating() {
    const res = await ratingServices.GetAll({ page: 1, limit: 100 });

    if (res) {
      setRatings({
        data: res.data,
        pagination: {
          current_page: res.current_page,
          per_page: res.per_page,
          total: res.total,
          last_page: res.last_page,
        },
      });
    }
  }

  return (
    <div className="col-span-12 grid grid-cols-5 gap-3">
      {ratings.data.map((rating, index) => (
        <div
          key={index}
          className="flex items-center justify-between p-4 bg-white shadow-lg rounded-lg shadow-xs"
        >
          <div className="flex items-center">
            <div className="flex flex-col">
              <div className="flex ">
                {new Array(5).fill("").map((_, index) => {
                  return (
                    <FaStar
                      key={index}
                      className={`${
                        index < rating.rating
                          ? "text-yellow-400"
                          : "text-gray-300"
                      }`}
                    />
                  );
                })}
              </div>
              <span className="text-sm font-semibold text-gray-900 line-clamp-1 mt-2">
                {rating.created_user.name}
              </span>
              <span className="text-xs text-gray-500 line-clamp-1">
                {new Date(rating.created_at).toLocaleDateString("id-ID", {
                  // weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
