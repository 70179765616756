import { useEffect, useState } from "react";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import LoadComponent from "src/components/load";
import { NavbarComponent } from "src/components/navbar";
import { ArticleServices } from "src/services/ArticleServices";
import { handleOtherStatusCodes } from "src/services/errors";

export function DetailEducationPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const articleServices = new ArticleServices();

  const [article, setArticle] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await articleServices.GetSingle({ id });
    if (res.code === 200) {
      setArticle(res.data);
    } else {
      handleOtherStatusCodes(res.code);
    }
  }

  if (!article) {
    return <LoadComponent />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Edukasi"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div>
        <img src={article.image} />
      </div>

      <div className="mt-4 w-11/12">
        <h4 className="f-h4">
          {article.title}
          <span className="f-p2-r ml-2">({article.published_at})</span>
        </h4>

        <div
          className="mt-4 prose lg:prose-xl"
          dangerouslySetInnerHTML={{ __html: article.content }}
        />
      </div>
    </div>
  );
}
