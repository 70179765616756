import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserLayout } from "src/layouts/user";
import { CommunityPage } from "src/pages/community";
import { DashboardPage } from "src/pages/dashboard";
import { EducationPage } from "src/pages/education";
import { NewsPage } from "src/pages/news";
import { MyProfilePage } from "src/pages/my-profile";
import { PromotionPage } from "src/pages/promotion";
import { StartPage } from "src/pages/start";
import { OrderPage } from "src/pages/order";
import { DetailNewsPage } from "src/pages/news/detail";
import { DetailEducationPage } from "src/pages/education/detail";
import { EditProfilePage } from "src/pages/my-profile/edit";
import { FeedbackPage } from "src/pages/my-profile/feedback";
import { DetailPromotionPage } from "src/pages/promotion/detail";
import { FAQPage } from "src/pages/my-profile/faq";
import { CreatePromotionPage } from "src/pages/promotion/create";
import { ProfilePromotionPage } from "src/pages/promotion/profile";

export default function UserRouterPage() {
  return (
    <UserLayout>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DashboardPage />} />

          {/* <Route path="/news" element={<NewsPage />} /> */}
          <Route path="/news/detail/:id/:title" element={<DetailNewsPage />} />

          <Route path="/education" element={<EducationPage />} />
          <Route
            path="/education/detail/:id/:title"
            element={<DetailEducationPage />}
          />

          <Route path="/promotion" element={<PromotionPage />} />
          <Route
            path="/promotion/detail/:id/:title"
            element={<DetailPromotionPage />}
          />
          <Route path="/promotion/create" element={<CreatePromotionPage />} />
          <Route
            path="/promotion/profile/:id"
            element={<ProfilePromotionPage />}
          />

          <Route path="/community" element={<CommunityPage />} />

          <Route path="/my-profile" element={<MyProfilePage />} />
          <Route path="/my-profile/edit" element={<EditProfilePage />} />
          <Route path="/my-profile/faq" element={<FAQPage />} />
          <Route path="/my-profile/feedback" element={<FeedbackPage />} />
          <Route
            path="/my-profile/promotion/:id"
            element={<ProfilePromotionPage />}
          />

          <Route path="/order" element={<OrderPage />} />

          <Route path="*" element={<DashboardPage />} exact />
        </Routes>
      </BrowserRouter>
    </UserLayout>
  );
}
