import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { CategoryPromotionServices } from "src/services/CategoryPromotionServices";

export function AdminCategoryPromotionAddPage() {
  const navigate = useNavigate();

  const categoryPromotionServices = new CategoryPromotionServices();

  const [form, setForm] = useState({
    name: "",
  });

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const res = await categoryPromotionServices.Create({ name: form.name });
    if (res) {
      toast.success("Berhasil menambahkan kategori promosi");
      navigate("/category-promotion");
    }
  }

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Buat Kategori Promosi</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                color="dark"
                label={"Nama"}
                name={"name"}
                value={form.name}
                handleChange={handleChange}
                type={"text"}
                required={true}
                placeholder={"Nama Kategori Promosi"}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <ButtonComponent
                color="bg-slate-400"
                action={() => {
                  navigate("/category-promotion");
                }}
                title={"Cancel"}
                type="button"
              />
              <ButtonComponent type="submit" title={"Submit"} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
