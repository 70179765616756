import { FiStar } from "react-icons/fi";
import { Currency } from "src/methods/Currency";
const { useNavigate } = require("react-router-dom");

export function PromotionCard(props) {
  const navigate = useNavigate();
  return (
    <button
      className="shadow-s1 bg-white rounded-md p-2 "
      onClick={() => {
        navigate("/promotion/detail/" + props.slug + "/" + props.name);
      }}
    >
      <div className="h-40 flex justify-center items-center relative overflow-hidden">
        <img src={props.image} className="w-full absolute" />
      </div>
      <div className="px-2 py-2">
        <p className="f-p2-r line-clamp-2 text-left">{props.name}</p>
        <p className="f-p2-m line-clamp-1 text-left">{props.category.name}</p>
        <p className="f-p2-m line-clamp-1 text-left text-primary-main">
          {Currency(props.price)}
        </p>
        <div className="flex items-center gap-1">
          <FiStar className="text-primary-main" size={16} />
          <p className="f-p2-r">
            {props.rating ? parseFloat(props.rating).toFixed(2) : "-"}
          </p>
        </div>
      </div>
    </button>
  );
}
