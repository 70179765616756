import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { InputTextareaTiny } from "src/components/input/input-textarea-tiny";
import dummy from "./data/category.json";
import { InputSelect } from "src/components/input/input-select";
import { ArticleServices } from "src/services/ArticleServices";
import { CategoryArticleServices } from "src/services/CategoryArticleServices";
import toast from "react-hot-toast";

export function AdminEducationAddPage() {
  const navigate = useNavigate();

  const articleServices = new ArticleServices();
  const categoryArticleServices = new CategoryArticleServices();

  const [form, setForm] = useState({
    title: "",
    image: "",
    preview: "",
    content: "",
    published_at: "",
    category_id: "",
  });

  const [categories, setCategories] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  async function fetchCategories() {
    const res = await categoryArticleServices.GetAll({ page: 1, limit: 100 });

    if (res) {
      setForm({ ...form, category_id: res.data.data[0].id });

      const tempCategories = res.data.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setCategories({
        data: tempCategories,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  function handleChange(e) {
    if (e.target.name == "image") {
      setForm({
        ...form,
        [e.target.name]: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      });
      return;
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const res = await articleServices.Create({
      title: form.title,
      image: form.image,
      content: form.content,
      published_at: form.date,
      category_id: form.category_id,
      status: "published",
    });

    if (res) {
      toast.success("Berhasil menambahkan edukasi");
      navigate("/education");
    }
  }

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Buat Edukasi</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                color="dark"
                label={"Judul"}
                name={"title"}
                value={form.title}
                handleChange={handleChange}
                type={"text"}
                required={true}
                placeholder={"Judul Edukasi"}
              />
            </div>
            <div className="mt-2">
              <InputSelect
                color="dark"
                label={"Kategori"}
                name={"category_id"}
                value={form.category_id}
                handleChange={handleChange}
                required={true}
                placeholder={"Kategori Edukasi"}
                options={categories?.data}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                color="dark"
                label={"Tanggal"}
                name={"date"}
                value={form.date}
                handleChange={handleChange}
                type={"date"}
                required={true}
                placeholder={"Tanggal Edukasi"}
              />
            </div>
            <div className="mt-2">
              <InputImage
                color="dark"
                label={"Gambar"}
                name={"image"}
                handleChange={handleChange}
                type={"image/png, image/jpeg"}
                preview={form.preview}
                required={true}
                placeholder={"Gambar Edukasi"}
              />
            </div>
            <div className="mt-2">
              <InputTextareaTiny
                label={"Isi Edukasi"}
                name={"content"}
                handleChange={handleChange}
                type={"text"}
                required={true}
                placeholder={"Content Edukasi"}
                color={"dark"}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <ButtonComponent
                color="bg-slate-400"
                action={() => {
                  navigate("/education");
                }}
                title={"Cancel"}
                type="button"
              />
              <ButtonComponent type="submit" title={"Submit"} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
