import { useEffect, useState } from "react";
import { FiArrowLeft, FiHome, FiPlus, FiStar } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ButtonComponent, ToggleButton } from "src/components/button";
import { NavbarComponent } from "src/components/navbar";
import { BottomNavbarComponent } from "src/components/navbar/BottomNavbarComponent";
import { Currency } from "src/methods/Currency";
import { CategoryPromotionServices } from "src/services/CategoryPromotionServices";
import { PromotionServices } from "src/services/PromotionServices";
import { handleOtherStatusCodes } from "src/services/errors";
import { PromotionCard } from "./component/PromotionCard";

export function PromotionPage() {
  const navigate = useNavigate();

  const categoryPromotionServices = new CategoryPromotionServices();
  const promotionServices = new PromotionServices();

  const [categories, setCategories] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });
  const [category, setCategory] = useState("");

  const [promotions, setPromotions] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchCategories();
    fetchPromotions();
  }, []);

  async function fetchCategories() {
    const res = await categoryPromotionServices.GetAll({ page: 0, limit: 100 });
    if (res) {
      setCategories({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
      // setCategory(res.data.data[0].id);
    } else {
      handleOtherStatusCodes(res.code);
    }
  }

  async function fetchPromotions(pCategory = "") {
    const res = await promotionServices.GetAll({
      page: 0,
      limit: 10,
      search: "",
      category_id: pCategory,
      created_by: "",
    });
    if (res) {
      setPromotions({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    } else {
      handleOtherStatusCodes(res.code);
    }
  }

  async function fetchMore(pCategory = "") {
    const res = await promotionServices.GetAll({
      page: promotions.pagination.current_page + 1,
      limit: 10,
      category_id: pCategory,
      created_by: "",
    });
    if (res) {
      setPromotions({
        data: [...promotions.data, ...res.data.data],
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Promosi"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="w-11/12 mt-4 overflow-scroll">
        <div className="flex gap-1">
          <ToggleButton
            key={"all"}
            title={"Semua"}
            active={category == ""}
            handleClick={() => {
              setCategory("");
              fetchPromotions("");
            }}
          />
          {categories.data.map((item, index) => {
            return (
              <ToggleButton
                key={index}
                title={item.name}
                active={category == item.id}
                handleClick={() => {
                  setCategory(item.id);
                  fetchPromotions(item.id);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="w-11/12 mt-4">
        <div className="grid grid-cols-2 gap-2">
          {promotions.data.length > 0 &&
            promotions.data.map((promotion, i) => (
              <PromotionCard
                key={i}
                id={i}
                slug={promotion.slug}
                image={promotion.image}
                name={promotion.name}
                category={promotion.category}
                price={promotion.price}
                rating={promotion.rating}
              />
            ))}
        </div>
      </div>

      <div className="mt-4 w-11/12">
        <ButtonComponent
          title={"Muat ulang"}
          action={() => {
            fetchMore(category);
          }}
        />
      </div>

      <br />
      <br />
      <br />
      <button
        onClick={() => {
          navigate("/promotion/create");
        }}
        className="fixed bottom-24 right-5 shadow-lg bg-primary-main text-white rounded-full h-14 w-14 flex justify-center items-center"
      >
        <FiPlus size={24} />
      </button>
      <div className="w-full fixed bottom-0">
        <BottomNavbarComponent />
      </div>
    </div>
  );
}
