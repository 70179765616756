import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class RatingServices {
  async GetAll({ page, limit }) {
    try {
      const res = await axios.get(
        `${baseUrl}/rating?page=${page}&limit=${limit}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ rating = "5" }) {
    try {
      const res = await axios.post(
        `${baseUrl}/rating`,
        { rating },
        {
          headers,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
