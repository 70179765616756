import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import imageLogo from "src/assets/logo.svg";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { AuthServices } from "src/services/AuthServices";

export function ForgotPasswordPage() {
  const navigate = useNavigate();
  const authServices = new AuthServices();

  const [form, setForm] = useState({ email: "" });

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    const res = authServices.ForgotPassword({ email: form.email });

    if (res) {
      toast.success("Berhasil mengirim email reset password");
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="relative h-full flex flex-col items-center justify-center"
    >
      <div>
        <img src={imageLogo} alt="Logo" />
      </div>

      <br />

      <div className="w-11/12">
        <h2 className="f-h2 text-primary-main">Lupa Password</h2>

        <div className="mt-6">
          <InputDefault
            label={"Email"}
            name={"email"}
            value={form.email}
            handleChange={handleChange}
            type={"email"}
            required={true}
            placeholder={"Masukkan email anda"}
          />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

      <div className="bg-primary-main absolute bottom-0 w-full flex justify-between items-center h-24 px-5">
        <p className="f-p2-r text-white">
          Belum memiliki akun? <br />
          <button
            type="button"
            onClick={() => {
              navigate("/sign-up");
            }}
            className="f-p2-m"
          >
            Registrasi
          </button>
        </p>
        <div className="w-1/3">
          <ButtonComponent title="Submit" type="submit" />
        </div>
      </div>
    </form>
  );
}
