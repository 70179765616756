import { useEffect, useState } from "react";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ButtonComponent, ToggleButton } from "src/components/button";
import { NavbarComponent } from "src/components/navbar";
import { BottomNavbarComponent } from "src/components/navbar/BottomNavbarComponent";
import { ArticleServices } from "src/services/ArticleServices";
import { CategoryArticleServices } from "src/services/CategoryArticleServices";
import { handleOtherStatusCodes } from "src/services/errors";

export function EducationPage() {
  const navigate = useNavigate();
  const articleServices = new ArticleServices();
  const categoryArticleServices = new CategoryArticleServices();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  const [articles, setArticles] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchCategories();
    fetchArticles();
  }, []);

  async function fetchCategories() {
    const res = await categoryArticleServices.GetAll({ page: 0, limit: 100 });
    if (res) {
      setCategories(res.data.data);
    }
  }

  async function fetchArticles(pCategory = "") {
    const res = await articleServices.GetAll({
      page: 0,
      limit: 10,
      category_id: pCategory,
    });
    if (res) {
      setArticles({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  async function fetchMore(pCategory = "") {
    const res = await articleServices.GetAll({
      page: articles.pagination.current_page + 1,
      limit: 10,
      category_id: pCategory,
    });
    if (res) {
      setArticles({
        data: [...articles.data, ...res.data.data],
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Edukasi"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="w-11/12 mt-4">
        <h4 className="f-h4">Artikel Edukasi</h4>

        <div className="mt-4 w-full overflow-scroll">
          <div className="flex gap-1">
            <ToggleButton
              key={"all"}
              title={"Semua"}
              active={category == ""}
              handleClick={() => {
                setCategory("");
                fetchArticles("");
              }}
            />
            {categories.map((item, index) => {
              return (
                <ToggleButton
                  key={index}
                  title={item.name}
                  active={category == item.id}
                  handleClick={() => {
                    setCategory(item.id);
                    fetchArticles(item.id);
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-4">
          {articles.length == 0 ? (
            <div></div>
          ) : (
            articles?.data?.map((item, index) => {
              return (
                <ArticleCard
                  key={index}
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  createdAt={item.created_at}
                  createdUser={item.created_user}
                  slug={item.slug}
                />
              );
            })
          )}
        </div>
      </div>

      <div className="mt-4 w-11/12">
        <ButtonComponent
          title={"Muat ulang"}
          action={() => {
            fetchMore(category);
          }}
        />
      </div>

      <br />
      <br />
      <br />
      <div className="w-full fixed bottom-0">
        <BottomNavbarComponent />
      </div>
    </div>
  );
}

function ArticleCard({ ...props }) {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate("/education/detail/" + props.slug + "/" + props.title);
      }}
      className="grid grid-cols-12 gap-2"
    >
      <div className="col-span-4 h-full relative overflow-hidden flex justify-center items-center">
        <img src={props.image} className="w-full h-auto absolute" />
      </div>
      <div className="col-span-8 flex flex-col justify-center h-full">
        <p className="f-p1-m line-clamp-2 text-left">{props.title}</p>
        <p className="f-p2-m line-clamp-1 text-left">
          {new Date(props.createdAt).toLocaleDateString("id-ID", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        <p className="f-p2-r line-clamp-1 text-left">
          Dibuat oleh {props.createdUser.name}
        </p>
        {/* <p className="f-p2-r line-clamp-1 text-left">{props.category.name}</p> */}
      </div>
    </button>
  );
}
