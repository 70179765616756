import { NavbarComponent } from "src/components/navbar";
import { FiHome, FiArrowLeft } from "react-icons/fi";
import iconEducation from "src/assets/icon/online-learning.svg";
import iconPromotion from "src/assets/icon/promotion.svg";
import iconCommunity from "src/assets/icon/communities.svg";
import { useNavigate } from "react-router-dom";
import { BottomNavbarComponent } from "src/components/navbar/BottomNavbarComponent";
import { NewsServices } from "src/services/NewsServices";
import { useEffect, useState } from "react";
import { handleOtherStatusCodes } from "src/services/errors";
import { ButtonComponent, ToggleButton } from "src/components/button";
import { CategoryNewsServices } from "src/services/CategoryNewsServices";

export function DashboardPage() {
  const navigate = useNavigate();
  const newsServices = new NewsServices();
  const categoryNewsServices = new CategoryNewsServices();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  const [newses, setNewses] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchCategories();
    fetchNews();
  }, []);

  async function fetchCategories() {
    const res = await categoryNewsServices.GetAll({ page: 0, limit: 100 });
    if (res) {
      setCategories(res.data.data);
    }
  }

  async function fetchNews(pCategory = "") {
    const res = await newsServices.GetAll({
      page: 0,
      limit: 10,
      search: "",
      category_id: pCategory,
    });
    if (res.code === 200) {
      setNewses({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }

  async function fetchMore(pCategory = "") {
    const res = await newsServices.GetAll({
      page: newses.pagination.current_page + 1,
      limit: 10,
      search: "",
      category_id: pCategory,
    });
    if (res) {
      setNewses({
        data: [...newses.data, ...res.data.data],
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    }
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Art Banyuwangi"
          type="dark"
          // leftIcon={FiArrowLeft}
          handleLeft={() => {}}
          // rightIcon={FiHome}
          handleRight={() => {}}
        />
      </div>

      <div className="w-11/12 flex justify-around mt-6">
        <MenuCard
          title="Edukasi"
          image={iconEducation}
          handleClick={() => {
            navigate("/education");
          }}
        />
        <MenuCard
          title="Promosi"
          image={iconPromotion}
          handleClick={() => {
            navigate("/promotion");
          }}
        />
        <MenuCard
          title="Komunitas"
          image={iconCommunity}
          handleClick={() => {
            navigate("/community");
          }}
        />
      </div>

      <div className="w-11/12 mt-6">
        <h4 className="f-h4">Berita Banyuwangi</h4>

        <div className="mt-4 w-full overflow-scroll">
          <div className="flex gap-1">
            <ToggleButton
              key={"all"}
              title={"Semua"}
              active={category == ""}
              handleClick={() => {
                setCategory("");
                fetchNews("");
              }}
            />
            {categories.map((item, index) => {
              return (
                <ToggleButton
                  key={index}
                  title={item.name}
                  active={category == item.id}
                  handleClick={() => {
                    setCategory(item.id);
                    fetchNews(item.id);
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 mt-4">
          {newses.length == 0 ? (
            <div></div>
          ) : (
            newses?.data?.map((item, index) => {
              return (
                <NewsCard
                  key={index}
                  image={item.image}
                  id={item.id}
                  slug={item.slug}
                  title={item.title}
                  published_at={item.published_at}
                />
              );
            })
          )}
        </div>
      </div>

      <div className="mt-4 w-11/12">
        <ButtonComponent
          title={"Muat ulang"}
          action={() => {
            fetchMore(category);
          }}
        />
      </div>

      <br />
      <br />
      <br />
      <div className="w-full fixed bottom-0">
        <BottomNavbarComponent />
      </div>
    </div>
  );
}

function MenuCard({ ...props }) {
  return (
    <button onClick={props.handleClick}>
      <div className="rounded-xl overflow-hidden bg-primary-surface bg-opacity-40 w-20 h-20 flex justify-center items-center">
        <img src={props.image} alt="menu" />
      </div>
      <h3 className="f-p2-m text-center mt-2">{props.title}</h3>
    </button>
  );
}

function NewsCard({ ...props }) {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        navigate("/news/detail/" + props.slug + "/" + props.title);
      }}
      className="flex flex-col items-center"
    >
      <div className="h-40 w-full relative overflow-hidden flex justify-center items-center">
        <img src={props.image} alt="menu" className="w-full h-auto absolute" />
      </div>
      <p className="f-p2-m line-clamp-2 mt-2">{props.title}</p>
      <p className="f-p2-r line-clamp-1 mt-1">{props.published_at}</p>
    </button>
  );
}
