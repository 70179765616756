import { useState } from "react";
import toast from "react-hot-toast";
import { FaStar } from "react-icons/fa";
import { FiArrowLeft, FiHome, FiSmile, FiStar } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { NavbarComponent } from "src/components/navbar";
import { RatingServices } from "src/services/RatingServices";

export function FeedbackPage() {
  const navigate = useNavigate();

  const ratingServices = new RatingServices();

  const [star, setStar] = useState(0);

  async function handleSubmit(star) {
    setStar(star);
    setTimeout(async () => {
      const res = await ratingServices.Create({ rating: star });
      if (res) {
        toast.success("Terima kasih atas umpan balik anda");
        navigate("/");
      }
    }, 500);
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Umpan Balik"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="w-11/12 mt-4 h-96 bg-white shadow-lg rounded-xl flex flex-col justify-center">
        <FiSmile className="text-6xl text-primary-main mx-auto" />
        <h4 className="f-h4 text-center mt-2">
          Berikan penilan anda terhadap aplikasi ini
        </h4>

        <div className="flex justify-center items-center mt-4 gap-2">
          <StarComponent
            handleClick={() => {
              handleSubmit(1);
            }}
            active={star >= 1}
          />
          <StarComponent
            handleClick={() => {
              handleSubmit(2);
            }}
            active={star >= 2}
          />
          <StarComponent
            handleClick={() => {
              handleSubmit(3);
            }}
            active={star >= 3}
          />
          <StarComponent
            handleClick={() => {
              handleSubmit(4);
            }}
            active={star >= 4}
          />
          <StarComponent
            handleClick={() => {
              handleSubmit(5);
            }}
            active={star >= 5}
          />
        </div>
      </div>
    </div>
  );
}

function StarComponent({ ...props }) {
  return (
    <button
      onClick={props.handleClick}
      className={`${props.active ? "text-yellow-400" : "text-slate-300"}`}
    >
      <FaStar size={40} />
    </button>
  );
}
