import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { PaginationComponent } from "src/components/pagination";
import { TransactionServices } from "src/services/TransactionServices";
import { handleOtherStatusCodes } from "src/services/errors";
import { TableOrderComponent } from "src/components/table/TableOrderComponent";
export function AdminOrderPage() {
  const navigate = useNavigate();

  const transactionServices = new TransactionServices();

  const [search, setSearch] = useState("");
  const [transactions, setTransactions] = useState({
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      last_page: 0,
    },
  });

  useEffect(() => {
    fetchTransactions();
  }, []);

  async function fetchTransactions() {
    const res = await transactionServices.GetAll({ page: 1, limit: 10 });
    if (res.code === 200) {
      setTransactions({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    } else {
      toast.error("Gagal mengambil data");
    }
  }

  async function fetchMore({ index }) {
    const res = await transactionServices.GetAll({
      page: index,
      limit: 10,
    });
    if (res.code === 200) {
      // console.log(res.data);
      setTransactions({
        data: [...res.data.data],
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    } else {
      handleOtherStatusCodes(res.code);
    }
  }

  async function handleUpdateStatus({ id, status }) {
    const res = await transactionServices.Status({ id, status });
    if (res) {
      toast.success("Berhasil mengubah status");
      fetchMore({ index: transactions.pagination.current_page });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "invoice_number", name: "Invoice" },
    { code: "promotion.name", name: "Promosi" },
    { code: "quantity", name: "Qty" },
    { code: "price", name: "Harga" },
    { code: "total_price", name: "Total" },
    { code: "status", name: "Status", type: "badge" },
    { code: "action", name: "Action" },
  ];

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <div className="flex gap-4 flex-col md:flex-row md:items-end">
        <div className="flex gap-2 items-end">
          <div className="">
            <InputDefault
              color="dark"
              label={"Search"}
              name={"search"}
              value={search}
              handleChange={(e) => {
                setSearch(e.target.value);
              }}
              type={"text"}
              required={true}
              placeholder={"Pencarian"}
            />
          </div>
          <div className="">
            <ButtonComponent title="Terapkan" type="button" />
          </div>
        </div>
      </div>
      <br />
      {/* Tabel */}
      <TableOrderComponent
        header={headerTable}
        action={[
          {
            color: "bg-green-500",
            name: "Sukses",
            callback: (id, data) => {
              handleUpdateStatus({
                id: data.invoice_number,
                status: "success",
              });
            },
          },
          {
            color: "bg-red-500",
            name: "Gagal",
            callback: async (id, data) => {
              handleUpdateStatus({
                id: data.invoice_number,
                status: "failed",
              });
            },
          },
        ]}
        data={transactions.data || []}
      />
      <br />
      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={transactions.pagination.current_page}
          data={Array.from(
            { length: transactions.pagination.last_page },
            (_, index) => index + 1
          )}
          callback={async (index) => {
            await fetchMore({ index: index + 1 });
          }}
        />
      </div>
    </div>
  );
}
