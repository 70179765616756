import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class PromotionServices {
  async GetAll({
    page,
    limit,
    search = "",
    category_id = "",
    created_by = "",
  }) {
    try {
      const res = await axios.get(
        `${baseUrl}/promotion-all?page=${page}&limit=${limit}&search=${search}&category_id=${category_id}&created_by=${created_by}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetSingle({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/promotion-single/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ name, image, price, description, category_id }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("category_id", category_id);

    try {
      const res = await axios.post(`${baseUrl}/promotion`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Update({ name, image, price, description, category_id, id, status }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("category_id", category_id);
    formData.append("status", status);

    try {
      const res = await axios.put(`${baseUrl}/promotion/${id}`, formData, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/promotion/${id}`, { headers });
      if (res.status === 200) {
        return true;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
