import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import imageLogo from "src/assets/logo.svg";
import { ButtonComponent } from "src/components/button";
import { InputDefault } from "src/components/input/input-default";
import { AuthServices } from "src/services/AuthServices";

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const { token } = useParams();

  const currentUrl = new URL(window.location.href);
  const email = currentUrl.searchParams.get("email");

  const authServices = new AuthServices();

  const [form, setForm] = useState({ password: "", password_confirmation: "" });

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const res = await authServices.ResetPassword({
      email: email,
      token: token,
      password: form.password,
      password_confirmation: form.password_confirmation,
    });

    if (res) {
      toast.success("Berhasil mereset password");
      navigate("/sign-in");
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="relative h-full flex flex-col items-center justify-center"
    >
      <div>
        <img src={imageLogo} alt="Logo" />
      </div>

      <br />

      <div className="w-11/12">
        <h2 className="f-h2 text-primary-main">Reset Password</h2>

        <div className="mt-6">
          <InputDefault
            label={"Password Baru"}
            name={"password"}
            value={form.password}
            handleChange={handleChange}
            type={"password"}
            required={true}
            placeholder={"Masukkan password baru"}
          />
        </div>
        <div className="mt-4">
          <InputDefault
            label={"Konfirmasi Password"}
            name={"password_confirmation"}
            value={form.password_confirmation}
            handleChange={handleChange}
            type={"password"}
            required={true}
            placeholder={"Ulangi password"}
          />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

      <div className="bg-primary-main absolute bottom-0 w-full flex justify-between items-center h-24 px-5">
        <p className="f-p2-r text-white">
          Belum memiliki akun? <br />
          <button
            type="button"
            onClick={() => {
              navigate("/sign-up");
            }}
            className="f-p2-m"
          >
            Registrasi
          </button>
        </p>
        <div className="w-1/3">
          <ButtonComponent title="Submit" type="submit" />
        </div>
      </div>
    </form>
  );
}
