import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class TransactionServices {
  async GetAll({ page, limit }) {
    try {
      const res = await axios.get(
        `${baseUrl}/transaction?page=${page}&limit=${limit}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetHistory({ page, limit, status }) {
    try {
      const res = await axios.get(
        `${baseUrl}/transaction-history?page=${page}&limit=${limit}&status=${status}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetSingle({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/transaction/${id}`, { headers });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ promotion_id, quantity }) {
    const formData = new FormData();
    formData.append("promotion_id", promotion_id);
    formData.append("quantity", quantity);

    try {
      const res = await axios.post(`${baseUrl}/transaction`, formData, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Update({ id, quantity }) {
    const formData = new FormData();
    formData.append("quantity", quantity);

    try {
      const res = await axios.put(`${baseUrl}/transaction/${id}`, formData, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Status({ id, status }) {
    // console.log(id, status);
    try {
      const res = await axios.put(
        `${baseUrl}/transaction/${id}/status`,
        { status },
        { headers }
      );
      if (res.status === 200) {
        return true;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/transaction/${id}`, {
        headers,
      });
      if (res.status === 204) {
        return true;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
