import {
  FiArrowDown,
  FiArrowLeft,
  FiChevronDown,
  FiHome,
  FiSmile,
  FiStar,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { NavbarComponent } from "src/components/navbar";
import jsonFAQ from "src/data/faq.json";

export function FAQPage() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="FAQ"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="w-11/12 mt-4">
        <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto">
          {jsonFAQ.data.map((item, index) => (
            <div className="py-5">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                  <span className="f-p1-m"> {item.question}</span>
                  <span className="transition group-open:rotate-180">
                    <FiChevronDown />
                  </span>
                </summary>
                <p className="f-p2-r mt-3 group-open:animate-fadeIn">
                  {item.answer}
                </p>
              </details>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
