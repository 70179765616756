import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminLayout } from "src/layouts/admin";
import { AdminDashboardPage } from "src/pages/admin";
import { AdminCategoryEducationPage } from "src/pages/admin/category-education";
import { AdminCategoryEducationAddPage } from "src/pages/admin/category-education/add";
import { AdminCategoryEducationEditPage } from "src/pages/admin/category-education/edit";
import { AdminCategoryNewsPage } from "src/pages/admin/category-news";
import { AdminCategoryNewsAddPage } from "src/pages/admin/category-news/add";
import { AdminCategoryNewsEditPage } from "src/pages/admin/category-news/edit";
import { AdminCategoryPromotionPage } from "src/pages/admin/category-promotion";
import { AdminCategoryPromotionAddPage } from "src/pages/admin/category-promotion/add";
import { AdminCategoryPromotionEditPage } from "src/pages/admin/category-promotion/edit";
import { AdminEducationPage } from "src/pages/admin/education";
import { AdminEducationAddPage } from "src/pages/admin/education/add";
import { AdminEducationEditPage } from "src/pages/admin/education/edit";
import { AdminMyProfilePage } from "src/pages/admin/my-profile";
import { AdminEditMyProfilePage } from "src/pages/admin/my-profile/edit";
import { AdminNewsPage } from "src/pages/admin/news";
import { AdminNewsAddPage } from "src/pages/admin/news/add";
import { AdminNewsEditPage } from "src/pages/admin/news/edit";
import { AdminOrderPage } from "src/pages/admin/order";

export default function AdminRouterPage() {
  return (
    <BrowserRouter>
      <AdminLayout>
        <Routes>
          <Route path="/" element={<AdminDashboardPage />} />

          <Route path="/news" element={<AdminNewsPage />} />
          <Route path="/news/add" element={<AdminNewsAddPage />} />
          <Route path="/news/edit/:id" element={<AdminNewsEditPage />} />

          <Route path="/education" element={<AdminEducationPage />} />
          <Route path="/education/add" element={<AdminEducationAddPage />} />
          <Route
            path="/education/edit/:id"
            element={<AdminEducationEditPage />}
          />

          <Route path="/category-news" element={<AdminCategoryNewsPage />} />
          <Route
            path="/category-news/add"
            element={<AdminCategoryNewsAddPage />}
          />
          <Route
            path="/category-news/edit/:id"
            element={<AdminCategoryNewsEditPage />}
          />

          <Route
            path="/category-education"
            element={<AdminCategoryEducationPage />}
          />
          <Route
            path="/category-education/add"
            element={<AdminCategoryEducationAddPage />}
          />
          <Route
            path="/category-education/edit/:id"
            element={<AdminCategoryEducationEditPage />}
          />

          <Route
            path="/category-promotion"
            element={<AdminCategoryPromotionPage />}
          />
          <Route
            path="/category-promotion/add"
            element={<AdminCategoryPromotionAddPage />}
          />
          <Route
            path="/category-promotion/edit/:id"
            element={<AdminCategoryPromotionEditPage />}
          />

          <Route path="/order" element={<AdminOrderPage />} />

          <Route path="/my-profile" element={<AdminMyProfilePage />} />
          <Route path="/my-profile/edit" element={<AdminEditMyProfilePage />} />

          <Route path="*" element={<AdminDashboardPage />} exact />
        </Routes>
      </AdminLayout>
    </BrowserRouter>
  );
}
