import { useEffect, useState } from "react";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import LoadComponent from "src/components/load";
import { NavbarComponent } from "src/components/navbar";
import { NewsServices } from "src/services/NewsServices";
import { handleOtherStatusCodes } from "src/services/errors";

export function DetailNewsPage() {
  const navigate = useNavigate();

  const { id } = useParams();
  const newsServices = new NewsServices();

  const [news, setNews] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await newsServices.GetSingle({ id });
    if (res.code === 200) {
      setNews(res.data);
    } else {
      handleOtherStatusCodes(res.code);
    }
  }

  if (!news) {
    return <LoadComponent />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <NavbarComponent
          title="Berita"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div>
        <img src={news.image} />
      </div>

      <div className="mt-4 w-11/12">
        <h4 className="f-h4">
          {news.title}
          <span className="f-p2-r ml-2">({news.published_at})</span>
        </h4>

        <div
          className="mt-4 prose lg:prose-xl"
          dangerouslySetInnerHTML={{ __html: news.content }}
        />
      </div>
    </div>
  );
}
