import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class CategoryPromotionServices {
  async GetAll({ page, limit, search = "" }) {
    try {
      const res = await axios.get(
        `${baseUrl}/category-promotion-all?page=${page}&limit=${limit}&search=${search}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async GetSingle({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/category-promotion/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Create({ name }) {
    const formData = new FormData();
    formData.append("name", name);

    try {
      const res = await axios.post(`${baseUrl}/category-promotion`, formData, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Update({ name, id }) {
    try {
      const res = await axios.put(
        `${baseUrl}/category-promotion/${id}`,
        { name },
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }

  async Delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/category-promotion/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  }
}
