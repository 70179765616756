import { useEffect, useState } from "react";
import { FiArrowLeft, FiHome, FiSmile, FiStar } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ButtonComponent, ToggleButton } from "src/components/button";
import LoadComponent from "src/components/load";
import { NavbarComponent } from "src/components/navbar";
import { BottomNavbarComponent } from "src/components/navbar/BottomNavbarComponent";
import { TransactionServices } from "src/services/TransactionServices";
import { handleOtherStatusCodes } from "src/services/errors";
import { FaStar } from "react-icons/fa";
import { InputTextarea } from "src/components/input/input-textarea";
import { useRef } from "react";
import { ReviewerServices } from "src/services/ReviewerServices";
import toast from "react-hot-toast";
import { useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { Currency } from "src/methods/Currency";

export function OrderPage() {
  const navigate = useNavigate();

  const transactionServices = new TransactionServices();
  const reviewerServices = new ReviewerServices();

  const { user } = useContext(UserContext);

  const [transactions, setTransactions] = useState(null);
  const [categories, setCategories] = useState(["success", "failed"]);
  const [category, setCategory] = useState("success");

  const [form, setForm] = useState({
    star: 0,
    feedback: "",
  });

  const [orderSelected, setOrderSelected] = useState(null);

  useEffect(() => {
    fetchTransactions();
  }, []);

  async function fetchTransactions(pCategory = "success") {
    const res = await transactionServices.GetHistory({
      page: 0,
      limit: 10,
      status: pCategory,
    });
    if (res.code === 200) {
      setTransactions({
        data: res.data.data,
        pagination: {
          current_page: res.data.current_page,
          per_page: res.data.per_page,
          total: res.data.total,
          last_page: res.data.last_page,
        },
      });
    } else {
      handleOtherStatusCodes(res.code);
    }
  }

  async function handleSubmit() {
    if (form.star == 0) return;
    const res = await reviewerServices.Create({
      rating: form.star,
      comment: form.feedback,
      transaction_id: orderSelected.id,
    });
    if (res) {
      fetchTransactions();
      toast.success("Terima kasih atas umpan balik anda");
      setOrderSelected(null);
    }
  }

  return (
    <div className="flex flex-col items-center">
      {orderSelected != null ? (
        <dialog className="z-50 bg-black bg-opacity-30 w-full flex justify-center items-center min-h-screen">
          <div className="w-11/12 mt-4 py-4 h-fit bg-white shadow-lg rounded-xl flex flex-col justify-center items-center">
            <h4 className="f-h4 text-center mt-2 w-11/12">
              Berikan penilan anda terhadap produk ini
            </h4>

            <p className="mt-2 f-p1-m text-center w-11/12">
              {orderSelected.product.name}
            </p>

            <img src={orderSelected.product.image} className="w-11/12 mt-2" />

            <div className="flex justify-center items-center mt-4 gap-2">
              <StarComponent
                handleClick={() => {
                  setForm({ ...form, star: 1 });
                }}
                active={form.star >= 1}
              />
              <StarComponent
                handleClick={() => {
                  setForm({ ...form, star: 2 });
                }}
                active={form.star >= 2}
              />
              <StarComponent
                handleClick={() => {
                  setForm({ ...form, star: 3 });
                }}
                active={form.star >= 3}
              />
              <StarComponent
                handleClick={() => {
                  setForm({ ...form, star: 4 });
                }}
                active={form.star >= 4}
              />
              <StarComponent
                handleClick={() => {
                  setForm({ ...form, star: 5 });
                }}
                active={form.star >= 5}
              />
            </div>

            <div className="mt-4 w-11/12">
              <InputTextarea
                color={"dark"}
                placeholder="Masukkan umpan balik anda"
                value={form.feedback}
                handleChange={(e) =>
                  setForm({
                    ...form,
                    feedback: e.target.value,
                  })
                }
              />
            </div>

            <div className="mt-4 w-11/12 flex gap-2">
              <ButtonComponent
                action={() => {
                  setOrderSelected(null);
                }}
                title="Batal"
                color="bg-slate-400"
              />
              <ButtonComponent
                action={() => {
                  handleSubmit();
                }}
                title="Kirim"
              />
            </div>
          </div>
        </dialog>
      ) : null}

      <div className="w-full">
        <NavbarComponent
          title="Riwayat Pesanan"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="mt-4 w-11/12 overflow-scroll">
        <div className="flex gap-1">
          {categories.map((item, index) => {
            return (
              <ToggleButton
                key={index}
                title={item}
                active={category == item}
                handleClick={() => {
                  setCategory(item);
                  fetchTransactions(item);
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="mt-4 w-11/12 flex flex-col gap-3">
        {transactions == null ? (
          <LoadComponent />
        ) : transactions.data.length == 0 ? (
          <h5 className="f-p1-m">Tidak ada data</h5>
        ) : (
          transactions.data.map((item, index) => {
            return (
              <ProductOrderComponent
                key={index}
                user={user}
                id={item.id}
                total={item.total_price}
                product={item.promotion}
                quantity={item.quantity}
                status={item.status}
                created_at={item.created_at}
                rating={
                  item.promotion.review.find((item) => {
                    return item.created_by == user.id;
                  }) == undefined
                    ? null
                    : item.promotion.review.find((item) => {
                        return item.created_by == user.id;
                      }).rating
                }
                callback={(id, product) => {
                  setOrderSelected({
                    id: id,
                    product: product,
                  });
                }}
              />
            );
          })
        )}
      </div>

      <br />
      <br />
      <br />
      <div className="w-full fixed bottom-0">
        <BottomNavbarComponent />
      </div>
    </div>
  );
}

function ProductOrderComponent({
  id,
  product,
  quantity,
  status,
  created_at,
  total,
  rating,
  callback,
}) {
  return (
    <button className="w-full bg-white shadow-s1 rounded-lg p-4">
      <div className="flex gap-4">
        <div className="w-16 flex justify-center items-center relative overflow-hidden">
          <img
            src={product.image}
            className="w-full h-auto rounded-lg absolute"
          />
        </div>

        <div className="grow text-left">
          <h5 className="f-h5">{product.name}</h5>
          <p className="text-sm">{product.created_user.name}</p>
          <h5 className="f-p2-m text-primary-main mt-1">{Currency(total)}</h5>
        </div>

        <div className="w-16">
          <h5 className="f-p2-m">x{quantity}</h5>
        </div>
      </div>

      <div className="flex justify-between items-center mt-2">
        <h5 className="f-p2-r">
          {new Date(created_at).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </h5>

        {rating == null ? (
          <div className="w-40">
            <ButtonComponent
              title={"Beri Penilaian"}
              action={() => {
                callback(id, product);
              }}
            />
          </div>
        ) : (
          <div className="flex gap-0.5">
            <FaStar
              size={20}
              className={rating >= 1 ? "text-yellow-400" : "text-slate-300"}
            />
            <FaStar
              size={20}
              className={rating >= 2 ? "text-yellow-400" : "text-slate-300"}
            />
            <FaStar
              size={20}
              className={rating >= 3 ? "text-yellow-400" : "text-slate-300"}
            />
            <FaStar
              size={20}
              className={rating >= 4 ? "text-yellow-400" : "text-slate-300"}
            />
            <FaStar
              size={20}
              className={rating >= 5 ? "text-yellow-400" : "text-slate-300"}
            />
          </div>
        )}
      </div>
    </button>
  );
}

function StarComponent({ ...props }) {
  return (
    <button
      onClick={props.handleClick}
      className={`${props.active ? "text-yellow-400" : "text-slate-300"}`}
    >
      <FaStar size={40} />
    </button>
  );
}
